export enum FormPreset {
  CONTACT_FORM = 'contact-form',
  GET_SUBSCRIBERS = 'get-subscribers',
  PAYMENT_FORM = 'paymentForm01',
  POTENTIAL_CUSTOMERS = 'potential-customers',
  SALES_LEAD = 'sales-lead',
  SUPPORT_REQUEST = 'support-request',
  TESTIMONIAL = 'testimonial',
  JOB_APPLICATION = 'job-application',
  CUSTOMER_SATISFACTION = 'customer-satisfaction',
  PRICE_QUOTE = 'price-quote',
  CONTEST_ENTRY = 'contest-entry',
  QUIZ = 'quiz',
  MULTI_STEP_REGISTRATION_FORM = 'multi-step-registration-form',
  MULTI_STEP_SALES = 'multi-step-sales',
  REGISTRATION_FORM = 'registration-form',
  CUSTOM_REGISTRATION_WITH_COMMUNITY = 'customSignup01',
}
